<template>
  <div class="primary darken-2">
    <div class="px-6">
      <v-text-field autofocus clearable
                    hint="The name for the pattern"
                    color="secondary"
                    v-model="name"
                    placeholder="Name"/>
    </div>
    <div class="px-6">
      <v-text-field clearable
                    hint="The harmony to play at the start of the exercise in intervals, e.g 0 7"
                    color="secondary"
                    v-model="preHarmony"
                    placeholder="Pre harmony"/>
    </div>
    <div class="px-6">
      <v-text-field clearable
                    hint="The pattern in semi-tones seperated by spaces, e.g 0 2 4 2 0"
                    color="secondary"
                    v-model="pattern"
                    placeholder="Pattern"/>
    </div>
    <v-row no-gutters>
      <v-col class="text-center pa-3">
        <v-btn block :small="$vuetify.breakpoint.smAndDown"
               class="text-capitalize body-2"
               :color="getIsTransportStarted ? 'error' : 'success'"
               @click="play">
          <app-icon :icon="getIsTransportStarted ? 'stop' : 'play-arrow'" :size="20"/>
        </v-btn>
      </v-col>
      <v-col class="pa-3 text-center">
        <v-btn block :small="$vuetify.breakpoint.smAndDown"
               @click="addPattern">
          Add
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { scheduleIntervals }      from 'src/utilities/player-helpers'
import { stringPatternToArray }   from 'framework/utilities/Utilities'

export default {
  name:     'CreatePattern',
  props:    {
    patternType: {
      type:     String,
      required: true,
    },
  },
  data() {
    return {
      name:       '',
      preHarmony: '',
      pattern:    '',
    }
  },
  computed: {
    ...mapGetters('context', ['getDefaultNote']),
    ...mapGetters('transport', ['getIsTransportStarted']),
  },
  methods:  {
    ...mapActions('transport', ['startPlayback', 'stopPlayback']),
    play() {
      if(this.getIsTransportStarted) {
        this.stopPlayback()
      } else {
        const preHarmonyIntervals = stringPatternToArray(this.preHarmony)
        const intervals           = stringPatternToArray(this.pattern)
        scheduleIntervals(this.getDefaultNote, [preHarmonyIntervals, ...intervals])
        this.startPlayback()
      }
    },
    addPattern() {
      const name       = this.name
      const preHarmony = stringPatternToArray(this.preHarmony)
      const intervals  = stringPatternToArray(this.pattern)
      if(name && preHarmony && intervals) {
        this.$emit('pattern-created', { name, preHarmony, intervals })
      } else {
        this.$store.dispatch('view/setSnackbar', {
          active:  true,
          timeout: 4000,
          text:    'Please enter both name and pattern for your exercise.',
        })
      }
    },
  },
}
</script>

<style scoped>

</style>