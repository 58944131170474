<template>
  <v-menu
    v-model="isActive"
    :close-on-content-click="false"
    offset-y bottom>
    <template #activator="{ on }">
      <div v-on="on">
        <slot/>
      </div>
    </template>
    <v-row dense class="text-center primary" style="max-width: 400px">
      <v-tabs centered grow height="30" background-color="primary darken-1" v-model="activeTab"
              color="primary lighten-2">
        <v-tab v-for="type in types" :key="type" class="caption">
          {{ type }}
        </v-tab>
        <v-tab-item v-for="(type, typeIndex) in types" :key="type">
          <v-row dense justify="center" align="center" class="pa-3">
            <v-col v-for="(exercise, i) in exercises[typeIndex]" :key="i">
              <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
                     class="text-capitalize"
                     :color="exercise.name === currentExercise.name ? 'secondary' : 'primary darken-2'"
                     @click="$emit('updated', exercise)">
                {{ exercise.name }}
              </v-btn>
            </v-col>
            <v-col v-if="type!=='default'">
              <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
                     class="text-capitalize" @click="newExerciseDialog = true">
                <app-icon icon="add" size="16"/>
                Create
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
      <v-col :cols="12" class="text-center">
        <v-btn block
               class="text-capitalize body-2"
               :color="getIsTransportStarted ? 'error' : 'success'"
               @click="play">
          {{ currentExercise.name }}
          <app-icon :icon="getIsTransportStarted ? 'stop' : 'play-arrow'" :size="20"/>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="newExerciseDialog" max-width="350">
      <app-dialog @close="newExerciseDialog=false">
        <template #title>New Exercise</template>
        <create-pattern pattern-type="exercise" @pattern-created="addExercise"/>
      </app-dialog>
    </v-dialog>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import defaultExercises           from 'framework/resources/json/singing-exercises.json'
import { scheduleIntervals }      from 'src/utilities/player-helpers'
import CreatePattern              from 'src/components/CreatePattern'
import AppDialog                  from 'framework/ui/components/AppDialog'
import { localStore }             from 'framework/helpers/LocalStorageHandler'
import { EXERCISE_DB_NAME }       from 'misc/app-constants'

export default {
  name:       'SelectExercise',
  components: { AppDialog, CreatePattern },
  props:      {
    currentExercise: { type: Object, default: () => defaultExercises[0] }
  },
  mounted() {
    setTimeout(async () => {
      await this.updateUserExercises()
    }, 1000)
  },
  data() {
    return {
      defaultExercises,
      userExercises:     [],
      isActive:          false,
      newExerciseDialog: false,
      activeTab:         'default',
      types:             ['default', 'saved']
    }
  },
  computed:   {
    ...mapGetters('context', ['getDefaultNote']),
    ...mapGetters('transport', ['getIsTransportStarted']),
    ...mapGetters('user', ['getExercises', 'getIsLoggedIn']),
    exercises: function() {
      return [this.defaultExercises, this.userExercises]
    }
  },
  methods:    {
    ...mapActions('transport', ['startPlayback', 'stopPlayback']),
    ...mapActions('view', ['setSnackbar']),
    play() {
      if(this.getIsTransportStarted) {
        this.stopPlayback()
      } else {
        const intervals = [this.currentExercise.preHarmony, ...this.currentExercise.intervals]
        scheduleIntervals(this.getDefaultNote, intervals)
        this.startPlayback()
      }
    },

    async addExercise(exercise) {
      let text
      let creationSuccess = false
      if(this.getIsLoggedIn) {
        try {
          await this.getExercises.create(exercise, exercise.name)
          text            = 'Exercise created and added to your profile!'
          creationSuccess = true
        } catch (e) {
          text = 'An exercise with this name already exists, please choose a different name!'
        }
      } else {
        const exercises = await localStore.get(EXERCISE_DB_NAME) || []
        if(Array.isArray(exercises) && exercises.some(({ name }) => name === exercise.name)) {
          text = 'An exercise with this name already exists, please choose a different name!'
        } else {
          await localStore.set(EXERCISE_DB_NAME, [...exercises, exercise])
          text            = 'Exercise saved to your device!'
          creationSuccess = true
        }
      }
      await this.$store.dispatch('view/setSnackbar', {
        active:  true,
        timeout: 4000,
        text
      })
      if(creationSuccess) {
        this.newExerciseDialog = false
        await this.updateUserExercises()
      }
    },

    async updateUserExercises() {
      const exercises = []
      if(this.getIsLoggedIn) {
        let userExercises = await this.getExercises?.readAll()
        if(userExercises.length) {
          exercises.push(...userExercises)
        }
      }
      const localExercises = await localStore.get(EXERCISE_DB_NAME)
      if(Array.isArray(localExercises) && localExercises.length) {
        exercises.push(...localExercises)
      }
      this.userExercises = exercises
    }
  }
}
</script>

<style scoped>
</style>
